/* body {
    font: 14px "Century Gothic", Futura, sans-serif;
    margin: 20px;
  }
   */
  ol, ul {
    padding-left: 30px;
  }
  
  .board-row {
    width: 105px;
  }
  .board-row:after {
    clear: both;
    content: "";
    display: table;
  }
  
  .status {
    margin-bottom: 10px;
  }
  
  .square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    height: 35px;
    width: 35px;
  }
  
  .square:focus {
    outline: none;
  }
  
  .square.winner {
    color: red;
    border-color: blue;
  }
  
  .kbd-navigation .square:focus {
    background: #ddd;
  }
  
  .game {
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    margin: 5em auto;
    max-width: 500px;
    justify-content: center;
  }
  
  .game-info {
    margin-left: 20px;
  }
  