@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.container {
  max-width: 500px;
  margin: 5rem auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
}

.base-container{
  max-width: 100%;
  margin: 5rem auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1 1;
}

.form-control-check input {
  flex: 2 1;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}

.about-page-container {
  padding: 2rem;
  margin: 5rem auto;
  width: 80%;
  height: 100%;
  overflow: hidden;
  border: 1px solid steelblue;
}

@media only screen and (max-width: 768px) {
  .base-container{
    max-width: 100%;
    margin: 10rem auto;
    overflow: auto;
    min-height: 300px;
    border: 1px solid steelblue;
    padding: 20px;
    border-radius: 5px;
  }

  .container {
    max-width: 500px;
    margin: 10rem auto;
    overflow: auto;
    min-height: 300px;
    border: 1px solid steelblue;
    padding: 20px;
    border-radius: 5px;
  }

  .about-page-container {
    padding: 1rem;
    margin: 10rem auto;
    width: 95%;
    height: 100%;
    overflow: hidden;
    border: 1px solid steelblue;
    border-radius: 5px;
  }
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

.modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: row;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.p-description{
    width: 50%;
    text-align: justify;
}

.p-image{
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .modal-main {
        position:fixed;
        background: white;
        width: 80%;
        height: auto;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        align-self: center;
    }   
    .p-description{
        width: 90%;
        text-align: justify;
    }
    
    .p-image{
        width: 90%;
    }
}
.navContainer {
    position: fixed;
    top: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    background-color: steelblue;
}

.navLink {
    float: left;
    border-right:1px solid #bbb;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.navLink:hover:not(.active) {
    background-color: #111;
}

.right {
    float: right;
}

@media only screen and (max-width: 768px) {
    navContainer.navLink, 
    navContainer.right {float: none;}
  }

.footerContainer {
    background-color: steelblue;
    width: 100%;
    padding: 1rem;
    text-align: center;
}
.footerContainer li {
    list-style-type: none;
}
.socialIconStyle {
    width: 25px;
    padding: .3rem;
    border-radius: 50%;
}
.code-container{
    white-space: pre-wrap;
}
/* body {
    font: 14px "Century Gothic", Futura, sans-serif;
    margin: 20px;
  }
   */
  ol, ul {
    padding-left: 30px;
  }
  
  .board-row {
    width: 105px;
  }
  .board-row:after {
    clear: both;
    content: "";
    display: table;
  }
  
  .status {
    margin-bottom: 10px;
  }
  
  .square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    height: 35px;
    width: 35px;
  }
  
  .square:focus {
    outline: none;
  }
  
  .square.winner {
    color: red;
    border-color: blue;
  }
  
  .kbd-navigation .square:focus {
    background: #ddd;
  }
  
  .game {
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    margin: 5em auto;
    max-width: 500px;
    justify-content: center;
  }
  
  .game-info {
    margin-left: 20px;
  }
  
