.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

.modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: row;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.p-description{
    width: 50%;
    text-align: justify;
}

.p-image{
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .modal-main {
        position:fixed;
        background: white;
        width: 80%;
        height: auto;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        align-self: center;
    }   
    .p-description{
        width: 90%;
        text-align: justify;
    }
    
    .p-image{
        width: 90%;
    }
}